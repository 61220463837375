<template>
    <r-e-drawer class="drawer-house-info" :title="drawerTitle" :visible.sync="drawerVisible" :size="1150" modal
                wrapper-closable drawer-wrapper>
        <div slot="title" class="drawer-header flex align-center">
            <div class="drawer-title">{{ drawerTitle }}</div>
            <div class="room-status">{{ roomStatus }}</div>
        </div>

        <div class="drawer-container">
            <div style="position: fixed; right: 100px; top: 60px">
                <el-radio-group v-model="zUuid">
                    <el-radio-button v-for="item in ziList" :key="item.uuid" :label="item.uuid">
                        {{ item.insideRoomNumber }}
                    </el-radio-button>
                </el-radio-group>
            </div>
            <!--        zUuid区分展示哪个-->
            <div v-if="ziList.length === 0 || zUuid" class="flex">
                <div class="container-header flex align-center">
                    <div class="item text-center" v-for="item in houseTabOptions" @click="activeTab = item.name"
                         :class="{ 'is-active-tab': item.name === activeTab }" :key="item.name">
                        {{ item.label }}
                    </div>
                </div>
                <div style="margin-left: 20px" class="flex align-center">
                    <el-button type="primary" @click="goDoor">智能管控</el-button>
                </div>
            </div>
            <div v-else class="flex">
                <div class="container-header flex align-center">
                    <div class="item text-center" @click="activeTab = 'contract'"
                         :class="{ 'is-active-tab': 'contract' === activeTab }">
                        租房合同
                    </div>
                    <div class="item text-center" @click="activeTab = 'detail'"
                         :class="{ 'is-active-tab': 'detail' === activeTab }">
                        房间详情
                    </div>
                </div>
                <div style="margin-left: 20px" class="flex align-center">
                    <el-button type="primary" @click="goDoor">智能管控</el-button>
                </div>
            </div>

            <div class="container-content hidden-scrollbar">
                <component :is="activeTab" :current-house="currentHouse" :zUuid="zUuid" :ziList="ziList"
                           @apartmentSon="apartmentSon" @closeDrawer="closeDrawer" @handleSearch="handleSearch"/>
            </div>
        </div>
    </r-e-drawer>
</template>

<script>
import {houseTabOptions} from "@/views/rental-management/house-resource/data";
import {book, contract, bill, otherBill, detail, asset} from "./part";
import {apartmentSon, getHouseDetailApi} from "@/api/house-resource";

export default {
    props: {
        houseStatus: {
            type: Array,
            default: () => [],
        },
    },
    name: "drawer-house-info",
    data() {
        return {
            houseTabOptions,
            activeTab: "contract",
            drawerVisible: false,
            drawerTitle: "房源",
            roomStatus: "未出租",
            currentHouse: {},
            ziList: [],
            zUuid: null,
            houseInfo: {}
        };
    },
    components: {book, contract, bill, otherBill, detail, asset},
    methods: {
        // 打开房源详情，父组件调用此方法
        drawerHouseInfoInit(data) {
            const {address, state, apartmentUuid} = data;
            this.drawerTitle = address;
            this.roomStatus = this.createRoomStatus(state);
            this.drawerVisible = true;
            this.currentHouse = data;
            // this.zUuid = apartmentUuid;
            this.apartmentSon(apartmentUuid);
        },
        //获取房源状态
        createRoomStatus(status) {
            // 房间状态
            if (!this.houseStatus.map((v) => v.code).includes(status)) return "--";
            const {codeName} = this.houseStatus.find((v) => v.code === status);
            console.log('codeName',codeName);
            return codeName;
            
        },
        //查询子房间
        apartmentSon(uuid) {
            let that = this;
            apartmentSon(uuid).then((res) => {
                let {list} = res;
                that.ziList = list;
            });
        },
        //关闭房源详情
        closeDrawer() {
            this.drawerVisible = false;
        },

        goDoor() {
            const apartmerntName = this.drawerTitle;
            this.$router.push({name: "door-management", params: {data: {apartmerntName}}});
        },

        //刷新房间信息
        async handleSearch() {
            let that = this;
            const {apartmentUuid} = this.currentHouse;
            const uuid = that.zUuid || apartmentUuid;
            //调用接口刷新房间信息
            const {info = {}} = await getHouseDetailApi(uuid);
            this.drawerTitle = info.address;
            that.$emit('handleSearch');
        },
    },
    watch: {
        drawerVisible(value) {
            if (!value) this.zUuid = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.drawer-house-info {
    .drawer-header {
        .room-status {
            color: #1f7cf6;
            font-size: rem(14px);
            border-radius: 15px;
            padding: VH(3px) VW(10px);
            background: #cee7ff;
            margin-left: VW(10px);
        }
    }

    .drawer-container {
        margin-left: VW(5px);

        .container-header {
            width: fit-content;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

            .item {
                padding: VH(15px) VW(30px);
                color: #666;
                font-size: rem(16px);
                cursor: pointer;
                border-radius: 4px;
                margin-right: VW(5px);

                &:last-child {
                    margin-right: 0;
                }

                &.is-active-tab,
                &:hover {
                    color: #fff;
                    background: linear-gradient(80deg, #a8d4ff 0%, #2386f6 100%);
                    box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
                }
            }
        }

        .container-content {
            height: VH(870px);
            margin-top: VH(20px);
        }
    }

    /deep/ .container-content {
        .table-title {
            position: relative;
            padding-left: 10px;
            margin: 20px 0;

            &::before {
                width: 5px;
                height: 100%;
                content: "";
                background-color: #f5a623;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}
</style>
